import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StateProvider } from "./StateProvider";
import reducer, { initialState } from "./reducer";
import ReactGA from "react-ga4";

ReactGA.initialize("G-RB7R8Q472N");
let theme = createTheme({
  palette: {
    primary: {
      main: "#073763",
    },
    secondary: {
      main: "#edf2ff",
    },
    white: {
      main: "#ffffff",
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
