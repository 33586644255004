import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Skeleton } from "@mui/material";
import { RiRulerLine } from "react-icons/ri";
import { MdLocalShipping } from "react-icons/md";
import { FaWeightHanging } from "react-icons/fa";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ReactGA from 'react-ga4'


function ProductDescription() {
  const [{ }, dispatch] = useStateValue();
  const [data, setData] = useState();
  const { productID } = useParams();
  const [soldOut, setSoldOut] = useState(false);
  const [qtyValue, setQutyValue] = useState(1);

  let payload = {
    id: productID,
    qty: qtyValue,
    price: data?.price,
    priceID: data?.priceID,
    imageURL: data?.imgURL,
  };

  function addToBasket(payload) {
    dispatch({
      type: "ADD_TO_CART",
      payload,
    });
  }
  useEffect(() => {
    async function getProductDetails() {
      await fetch(
        "https://us-central1-mikes-website-61b1f.cloudfunctions.net/app/products",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: productID,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setSoldOut(data?.metadata?.sold_out);
          ReactGA.send({ hitType: "pageview", page: `/product/${data.id}`, title: `${data.name} Product Page` });
        });
    }
    getProductDetails();
  }, [productID]);


  return data ? (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%", transition: { duration: 1.2 } }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
      style={{ minHeight: "calc(100vh - 177px)" }}
    >
      <div className="productDetails bg-gradient-to-br min-h-screen from-[#0b5394] to-[#3d85c6]">
        <div className="px-8 py-4  mx-16 ">
          <div className="flex flex-col lg:flex-row gap-16">
            <div className="flex-[2] ">
              <div className="py-4 ">
                <img
                  src={`${data?.imgURL}`}
                  alt="product"
                  className="rounded-2xl max-h-[600px] mx-auto"
                />
              </div>
            </div>
            <div className="flex-[3] text-left text-white">
              <div className="py-4 relative">
                <h1 className="text-6xl font-bold ">{data?.name}</h1>

                <div className="pt-4 text-3xl font-semibold">
                  ${(data?.price / 100).toFixed(2)}
                </div>
                <hr />
                <div className="productDetails mt-4 text-lg max-w-full">
                  <div className="">{data?.metadata?.description_long}</div>
                </div>
                <div className="mt-4">
                  <div className="flex">
                    <input
                      type="number"
                      defaultValue={1}
                      value={qtyValue}
                      onChange={(e) => setQutyValue(Number(e.target.value))}
                      max={10}
                      name=""
                      id=""
                      className="rounded-l-xl w-16 border-none outline-none text-black pl-4 pr-1 py-2"
                    />
                    <button
                      onClick={() => addToBasket(payload)}
                      disabled={soldOut === "true" ? true : false}
                      className="px-16 hover:bg-[#0b5394] cursor-pointer transition-all duration-300 flex items-center justify-center rounded-r-xl"
                    >
                      {soldOut === "true" ? "SOLD OUT" : "Add to cart"}
                    </button>
                  </div>
                </div>
                <div className="mt-8">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="flex gap-2 justify-start items-center">
                        <RiRulerLine /> Dimensions
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="">
                      <div className="">
                        Height: {data?.metadata?.height} in.
                      </div>
                      <div className="py-2">
                        Width: {data?.metadata?.width} in.
                      </div>
                      <div className="">
                        Length: {data?.metadata?.length} in.
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="flex gap-2 justify-start items-center">
                        <MdLocalShipping /> Shipping & Returns
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="">SHIPPING POLICY</div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="flex gap-2 justify-start items-center">
                        <FaWeightHanging /> Weight
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="">{data?.metadata?.weight} oz</div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : (
    <div className="min-h-screen"></div>
  );
}

export default ProductDescription;
