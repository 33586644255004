import React from "react";
import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import OrderSuccessful from "../components/OrderSuccessful";
import OrderCanceled from "../components/OrderCanceled";
import ReactGA from 'react-ga4'
function Status() {

  let [searchParams, setSearchParams] = useSearchParams();

  const success = searchParams.get("success");
  const canceled = searchParams.get("canceled");

  let successBool = success === "true" ? true : false;
  let canceledBool = canceled === "true" ? true : false;

  successBool && ReactGA.send({ hitType: "pageview", page: "/status", title: "Successfull order Page" });
  canceledBool && ReactGA.send({ hitType: "pageview", page: "/status", title: "Cancel order Page" });
  return (
    <div className="min-h-screen max-w-screen-mobile-sm  md:max-w-screen-2xl mx-auto">
      {successBool && <OrderSuccessful />}

      {canceledBool && <OrderCanceled />}
    </div>
  );
}

export default Status;
