import React from "react";
import { motion } from "framer-motion";
import background from "../assets/bg.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactGA from 'react-ga4'


function FAQ() {
  ReactGA.send({ hitType: "pageview", page: "/faq", title: "FAQ Page" });
  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{
        width: "100%",
        transition: { delay: 0.5, duration: 1.2 },
        opacity: 1,
      }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ minHeight: "calc(100vh - 177px)" }}
    >
      <div
        className="h-[200px] overflow-hidden bg-no-repeat
         bg-cover bg-right-bottom md:bg-center"
        style={{
          backgroundImage: `url(${background})`,
        }}
      ></div>
      <div className="max-w-screen-mobile-sm sm:max-w-screen-sm md:max-w-screen-lg mx-auto text-left my-4">
        <div className="mx-4">
          <div className="text-3xl font-bold mt-4 mb-16">
            Frequently Asked Questions
          </div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Do I need to do anything differently when fishing with a feather
                attached to a spinner blade?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Often, the hook and the bottom of the blade will be inside the
                mouth of the fish. If necessary, pull the fish out of the net as
                the fish and the net are being laid down in the bottom of the
                boat so that the feather doesn’t get caught in the webbing of
                the net.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                Is any special care needed to take care of the feathers?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, there is. We recommend you pat the feather with a dry cloth
                or the shirt you are wearing and then let them air dry before
                you put them back in your tackle box. There are feather
                conditioning products such as Professional Pet Products Feather
                Fancy Bird Spray that contain natural ingredients you may
                consider applying occasionally if you think the feather needs to
                be moisturized. There are also fly-fishing products made from
                liquid silicone dressing that can be used to condition feathers.
                Amazon sells several nanotechnology-based water-protectant
                products that should work well on feathers.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </motion.div>
  );
}

export default FAQ;
