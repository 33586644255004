import React from "react";
import Button from "@mui/material/Button";
import { BsCartPlus } from "react-icons/bs";
import { useStateValue } from "../StateProvider";
import { Link } from "react-router-dom";

function ProductCard({ product }) {
  const [{ basket }, dispatch] = useStateValue();
  let payload = {
    id: product.id,
    qty: 1,
    price: product.default_price.unit_amount,
    priceID: product.default_price.id,
    imageURL: product.images[0],
  };

  function addToBasket(payload) {
    dispatch({
      type: "ADD_TO_CART",
      payload,
    });
  }
  return (
    <div className="h-fit p-2 bg-white rounded-md border border-[#00000047] shadow-lg">
      <div className="img-wrapper  m-2 p-10 flex justify-center rounded-xl ">
        <img
          src={product.images[0]}
          alt=""
          className="max-w-64 h-64 ronded-sm"
        />
      </div>
      <Link to={`../product/${product.id}`}>
        <div className="my-8 hover:text-blue-700">{product.name}</div>
        <div className="mb-8">{product.description}</div>
      </Link>

      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          addToBasket(payload);
        }}
      >
        <BsCartPlus /> ${(product.default_price.unit_amount / 100).toFixed(2)}
      </Button>
    </div>
  );
}

export default ProductCard;
