import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import logo from "../assets/logo-white.png";
function Footer() {
  let location = useLocation().pathname;

  return (
    <div className={`bg-[#6FA8DC] ${location === "/" ? "hidden" : null} px-8`}>
      <div className="max-w-screen-xl mx-auto py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          <div className="flex flex-col gap-16">
            <Link to="/" class="flex items-center">
              <img src={logo} class="h-6 mr-3 sm:h-9" alt="Flowbite Logo" />
              <div className="flex flex-col max-w-sm md:max-w-none">
                <span class=" text-white mobile-sm:text-xs self-center font-serif md:text-xl text-2xl font-semibold ">
                  No Longer Naked Lures <sup className="text-xs">TM</sup>
                </span>
                <span class="text-white mt-2 self-center font-serif text-sm md:text-base  ">
                  Lures Designed With Nature In
                  Mind <sup className="text-sm">TM</sup> 
                </span>
              </div>
            </Link>
            <div className="flex gap-8 text-3xl justify-between text-white">
              <AiFillFacebook />
              <AiOutlineTwitter />
              <AiFillInstagram />
            </div>
            <div className="text-white">
              Powered by{" "}
              <a
                href="http://www.lonewolfcode.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                lonewolfcode
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 font-bold text-white">
            <div className="heading text-3xl font-extrabold">Links</div>
            <Link to="/products" as={<div />}>
              <div className="text-blue-700">Lures</div>
            </Link>
            <Link to="/about" as={<div />}>
              <div className="text-blue-700">About</div>
            </Link>
            <Link to="/contact" as={<div />}>
              <div className="text-blue-700">Contact</div>
            </Link>
            <Link to="/faq" as={<div />}>
              <div className="text-blue-700">FAQ</div>
            </Link>
          </div>
          <div className="flex flex-col items-center gap-4 font-bold text-white">
            <div className="heading text-3xl font-extrabold">More</div>
            <div className="text-blue-700">Cookie Statement</div>
            <div className="text-blue-700">Privacy Statement</div>
            <div className="text-blue-700">Partner with us</div>
            <div className="text-blue-700">Shipping Policy</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
