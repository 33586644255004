import React from "react";
import { motion } from "framer-motion";
import { TextField, Button } from "@mui/material";
import { RiSendPlaneFill } from "react-icons/ri";
import background from "../assets/bg.jpg";
import ReactGA from 'react-ga4'


const Contact = () => {
  ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact Page" });
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%", transition: { duration: 1.2 } }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
      style={{ minHeight: "calc(100vh - 177px)" }}
    >
      {" "}
      <div
        className="h-[200px] overflow-hidden bg-no-repeat
     bg-cover bg-right-bottom md:bg-center"
        style={{
          backgroundImage: `url(${background})`,
        }}
      ></div>
      <div className="max-w-screen-xl mx-auto ">
        <div className="flex items-center justify-center p-4">
          <div className="p-4 w-1/2 rounded shadow-xl bg-[#6FA8DC] border border-[#00000047]">
            <div className="flex flex-col gap-4">
              <div className="text-left font-extrabold text-3xl text-white">
                Contact Us
              </div>
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                color="white"
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                color="white"
              />
              <TextField
                id="outlined-basic"
                label="Message"
                variant="outlined"
                color="white"
                multiline
                rows={4}
              />
              <Button
                variant="text"
                color="primary"
                endIcon={<RiSendPlaneFill />}
              >
                SEND
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
