import React from "react";
import { useStateValue } from "../StateProvider";
import CheckoutSidebarProduct from "./CheckoutSidebarProduct";

function CheckoutSidebar() {
  const [{ cartItems, totalPrice }] = useStateValue();
  console.log(cartItems);
  return (
    <div className="relative">
      {cartItems.map((product, index) => {
        return <CheckoutSidebarProduct key={index} id={product} />;
      })}
      {cartItems.length >= 1 && (
        <>
          <div className="mt-32 text-3xl text-right flex gap-4 justify-end">
            <div className="">Subtotal:</div>
            <div className="">${(totalPrice / 100).toFixed(2)}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default CheckoutSidebar;
