import React, { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import ReactGA from 'react-ga4'

function Products() {
  ReactGA.send({ hitType: "pageview", page: "/products", title: "Products Page" });
  const [productsLoading, setProductsLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  async function getProducts() {
    const response = await fetch(
      "https://us-central1-mikes-website-61b1f.cloudfunctions.net/app/get-product-info"
    )
      .then((data) => data.json())
      .then((data) => {
        setProductData(data.data);
        console.log(data.data);
      });
    const products = await response.json();
    setProductsLoading(false);

    return products;
  }
  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="max-w-screen-2xl min-h-screen mx-auto my-8 p-4">
      <div className="">
        <div className="text-3xl text-left my-16">Products</div>
      </div>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {productsLoading && (
          <>
            {productData.map((product, index) => {
              return <ProductCard key={index} product={product} />;
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default Products;
