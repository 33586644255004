export const initialState = {
  // basket: [],
  // customerInfo: [],
  // user: null,
  // reloadPaymentFlag: true,
  // checkoutInfo: {},
  // shippingRate: null,
  // tax: null,
  cartItems: [],
  totalPrice: 0,
};
//Selector
export const getBasketTotal = (basket) =>
  basket?.reduce((amount, item) => item.price + amount, 0);

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const { payload } = action;
      const item = state.cartItems.find((product) => product.id === payload.id);
      if (item) {
        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.id === payload.id
              ? {
                  ...item,
                  qty: item.qty + 1,
                }
              : item
          ),
          totalPrice: state.totalPrice + payload.price,
        };
      }
      return {
        ...state,
        cartItems: [...state.cartItems, payload],
        totalPrice: state.totalPrice + payload.price,
      };
    default:
      return state;
  }
};

export default reducer;
