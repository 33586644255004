import React, { useEffect, useState } from "react";
import { useStateValue } from "../StateProvider";
import { Skeleton } from "@mui/material";
function CheckoutSidebarProduct(props) {
  const [data, setData] = useState();
  const [{ basket }] = useStateValue();
  console.log("props", props);
  useEffect(() => {
    async function getProductDetails() {
      await fetch(
        "https://us-central1-mikes-website-61b1f.cloudfunctions.net/app/products",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: props?.id?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => setData(data));
      return data;
    }
    getProductDetails();
  }, []);

  return (
    <div className="hover:bg-[#3d86c679] transition-all duration-300">
      <div className="p-4">
        <div className="flex justify-between">
          {data?.imgURL ? (
            <div className="rounded-full self-center aspect-square h-16 overflow-hidden">
              <img
                src={`${data?.imgURL}`}
                className="w-16 aspect-square"
                alt=""
              />
            </div>
          ) : (
            <Skeleton
              variant="circular"
              animation="wave"
              width={64}
              height={64}
            />
          )}

          <div className="">
            <div className="w-full mb-4">
              {data?.name ? (
                data.name
              ) : (
                <Skeleton animation="wave" width={150} height={12} />
              )}
            </div>
            <div className="text-right">
              {data?.price ? (
                "$" + (data.price / 100).toFixed(2)
              ) : (
                <div className="flex justify-end">
                  <Skeleton animation="wave" width={100} height={12} />
                </div>
              )}
            </div>
            <div className="text-right">
              QTY:{" "}
              {props.id.qty ? (
                props.id.qty
              ) : (
                <Skeleton animation="wave" width={100} height={12} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutSidebarProduct;
