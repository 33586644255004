import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material/";
import { motion } from "framer-motion";
import bg from "../assets/bg.jpg";
import ReactGA from 'react-ga4'


function Home() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
  return (
    <motion.div
      className="home"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
      initial={{ width: 0, height: "100vh", opacity: 0 }}
      animate={{
        width: "100%",
        opacity: 1,
        transition: { delay: 0.5, duration: 1.2 },
      }}
      transition={{ duration: 0.5 }}
      exit={{ scale: 0.01 }}
    >
      {/* HEADER CONTAINER */}
      <div
        className="headerContainer"
        style={{
          position: "relative",
          top: "30%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#FFF",
        }}
      >
        <div className="text-[#073763] text-6xl md:text-9xl mb-4">
          No Longer Naked Lures{" "}
          <sup className="text-xl mb-4 -top-[2.1rem] -left-[1.5rem] md:-top-[2.5rem]">
            TM
          </sup>
        </div>
        <div className="text-[#073763]  mobile-sm:text-3xl  md:text-6xl mb-8">
          Lures Designed With Nature In Mind
          <sup className="text-xl mb-4 -top-[1.1rem] md:-top-[2.5rem] -right-[0].5rem]">
            TM
          </sup>{" "}
        </div>
        <Button
          component={Link}
          variant="contained"
          to="/products"
          color="primary"
        >
          ORDER NOW
        </Button>
      </div>
      {/* WAVE CONTAINER */}
      <div className="svg-container">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
            <linearGradient id="gradient">
              <stop
                className="stop1"
                offset="0%"
                stopColor="rgba(84,58,183,0.7)"
              />
              <stop
                className="stop2"
                offset="5%"
                stopColor="rgba(76,69,184,1)"
              />
              <stop
                className="stop3"
                offset="10%"
                stopColor="rgba(67,81,185,1)"
              />
              <stop
                className="stop4"
                offset="15%"
                stopColor="rgba(59,92,186,1)"
              />
              <stop
                className="stop5"
                offset="20%"
                stopColor="rgba(50,104,187,1)"
              />
              <stop
                className="stop6"
                offset="25%"
                stopColor="rgba(42,115,188,1)"
              />
              <stop
                className="stop7"
                offset="30%"
                stopColor="rgba(34,126,189,1)"
              />
              <stop
                className="stop8"
                offset="35%"
                stopColor="rgba(25,138,190,1)"
              />
              <stop
                className="stop9"
                offset="40%"
                stopColor="rgba(17,149,191,1)"
              />
              <stop
                className="stop10"
                offset="45%"
                stopColor="rgba(8,160,192,1)"
              />
              <stop
                className="stop11"
                offset="50%"
                stopColor="rgba(0,172,193,1)"
              />
              <stop
                className="stop12"
                offset="55%"
                stopColor="rgba(8,160,192,1)"
              />
              <stop
                className="stop13"
                offset="60%"
                stopColor="rgba(17,149,191,1)"
              />
              <stop
                className="stop14"
                offset="65%"
                stopColor="rgba(25,138,190,1)"
              />
              <stop
                className="stop15"
                offset="70%"
                stopColor="rgba(34,126,189,1)"
              />
              <stop
                className="stop16"
                offset="75%"
                stopColor="rgba(42,115,188,1)"
              />
              <stop
                className="stop17"
                offset="80%"
                stopColor="rgba(50,104,187,1)"
              />
              <stop
                className="stop18"
                offset="85%"
                stopColor="rgba(59,92,186,1)"
              />
              <stop
                className="stop19"
                offset="90%"
                stopColor="rgba(67,81,185,1)"
              />
              <stop
                className="stop20"
                offset="95%"
                stopColor="rgba(76,69,184,1)"
              />
              <stop
                className="stop2"
                offset="100%"
                stopColor="rgba(0,172,192,0.5)"
              />
            </linearGradient>
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="#6fa8dc" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="#3d85c6" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="#0b5394" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#073763" />
          </g>
        </svg>
      </div>
      {/* FISH CONTAINER  */}
      <div
        className="fish-container"
        style={{
          position: "fixed",
          bottom: "0%",
          width: "100%",
          height: "130px",
          overflow: "hidden",
        }}
      >
        <img className="fish" src="/Walleye-blue.png" alt="swimming fish" />
        <img className="fish2" src="/Muskey-right.png" alt="swimming fish" />
        <img
          className="fish3 invisible md:visible"
          src="/Walleye-blue.png"
          alt="swimming fish"
        />
        <img
          className="fish4 invisible md:visible"
          src="/Muskey-right.png"
          alt="swimming fish"
        />

        <img
          className="fish5 invisible md:visible"
          src="/Walleye-tan.png"
          alt="swimming fish"
        />
        <img
          className="fish6 invisible md:visible"
          src="/Muskey-left.png"
          alt="swimming fish"
        />
        <img className="fish7" src="/Walleye-tan.png" alt="swimming fish" />
        <img className="fish8" src="/Muskey-left.png" alt="swimming fish" />
      </div>
    </motion.div>
  );
}

export default Home;
