import React, { useState, useRef, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Drawer, Button } from "@mui/material/";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCartFill } from "react-icons/bs";
import CheckoutSidebar from "./CheckoutSidebar";
import logo from "../assets/logo-white.png";
import { useStateValue } from "../StateProvider";
import { motion, AnimatePresence } from "framer-motion";

function Navbar() {
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const navbarRef = useRef();

  const [{ cartItems }] = useStateValue();

  const pathname = location.pathname;
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(navbarRef);

  const handleClick = async () => {
    let line_items = cartItems.map((item) => ({
      price: item.priceID,
      quantity: item.qty,
    }));
    let response = await fetch(
      "https://us-central1-mikes-website-61b1f.cloudfunctions.net/app/create-checkout-session",

      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "/*",
        },
        body: JSON.stringify(line_items),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location = data.url;
      });
  };

  return (
    <nav
      ref={navbarRef}
      class="bg-[#6FA8DC] border-gray-200 px-2 sm:px-4 py-2.5  "
    >
      <div class="container relative flex flex-wrap items-center justify-between mx-auto">
        <Link to="/" class="flex items-center">
          <img src={logo} class="h-6 mr-3 sm:h-9" alt="NLNL Logo" />
          <span class="hidden sm:block text-white self-center font-serif text-xl font-semibold whitespace-nowrap ">
            No Longer Naked Lures <sup className="text-xs">TM</sup>
          </span>
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
          aria-controls="navbar-default"
          aria-expanded={open}
          onClick={() => setOpen(!open)}
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>{" "}
        <div class={`hidden w-full md:block md:w-auto`} id="navbar-default">
          <ul class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-[#6FA8DC] md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium md:border-0 md:bg-[#6FA8DC] md:justify-center md:items-center">
            <li>
              <Link
                to="/products"
                class={`${
                  pathname === "/products" ? "text-blue-700" : null
                } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
                aria-current="page"
                onClick={() => setOpen(false)}
              >
                Lures
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => setOpen(false)}
                class={`${
                  pathname === "/about" ? "text-blue-700" : null
                } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={() => setOpen(false)}
                class={`${
                  pathname === "/contact" ? "text-blue-700" : null
                } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                to="/faq"
                onClick={() => setOpen(false)}
                class={`${
                  pathname === "/faq" ? "text-blue-700" : null
                } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
              >
                FAQ
              </Link>
            </li>
            <li>
              <div
                onClick={() => setDrawerOpen(true)}
                class="flex justify-center relative cursor-pointer py-2 pl-3 pr-4 text-gray-700  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 t-gray-400"
              >
                <BsFillCartFill />
                <div className="absolute text-white invisible md:visible md:-right-4 md:-top-4 ">
                  {cartItems.length}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div class={` w-full md:block md:w-auto`} id="navbar-default">
              <ul class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-[#6FA8DC] md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium md:border-0 md:bg-[#6FA8DC] md:justify-center md:items-center">
                <li>
                  <Link
                    to="/products"
                    class={`${
                      pathname === "/products" ? "text-blue-700" : null
                    } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
                    aria-current="page"
                    onClick={() => setOpen(false)}
                  >
                    Lures
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    onClick={() => setOpen(false)}
                    class={`${
                      pathname === "/about" ? "text-blue-700" : null
                    } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    onClick={() => setOpen(false)}
                    class={`${
                      pathname === "/contact" ? "text-blue-700" : null
                    } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    onClick={() => setOpen(false)}
                    class={`${
                      pathname === "/faq" ? "text-blue-700" : null
                    } block py-2 pl-3 pr-4 rounded md:p-0 text-gray-700 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 `}
                  >
                    FAQ
                  </Link>
                </li>
                <li>
                  <div
                    onClick={() => setDrawerOpen(true)}
                    class="flex justify-center relative cursor-pointer py-2 pl-3 pr-4 text-gray-700  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 t-gray-400"
                  >
                    <BsFillCartFill />
                    <div className="absolute text-white invisible md:visible md:-right-4 md:-top-4 ">
                      {cartItems.length}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <>
        <Drawer
          variant="temporary"
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <div className="w-[25rem] relative">
            <div className="p-4">
              <div className="flex justify-end">
                <div
                  onClick={() => setDrawerOpen(false)}
                  className="p-2 hover:text-[#465c95] hover:bg-[#f8f8f8] transition-all duration-150"
                >
                  <AiOutlineClose />
                </div>
              </div>
              <CheckoutSidebar />
            </div>
          </div>
          <div className="absolute w-full flex flex-col justify-center bottom-8">
            <Button
              style={{ margin: "auto" }}
              disabled={cartItems.length >= 1 ? false : true}
              variant="outlined"
              color="primary"
              onClick={() => {
                setDrawerOpen(false);
                handleClick();
              }}
            >
              Check Out
            </Button>
          </div>
        </Drawer>
      </>
    </nav>
  );
}

export default Navbar;
