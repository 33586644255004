import React from "react";
import background from "../assets/bg.jpg";
import { motion } from "framer-motion";
import ReactGA from 'react-ga4'

const About = () => {
  ReactGA.send({ hitType: "pageview", page: "/about", title: "About Page" });
  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{
        width: "100%",
        transition: { delay: 0.5, duration: 1.2 },
        opacity: 1,
      }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ minHeight: "calc(100vh - 177px)" }}
    >
      <div className="min-h-screen">
        <div
          className="h-[200px] overflow-hidden bg-no-repeat
         bg-cover bg-right-bottom md:bg-center"
          style={{
            backgroundImage: `url(${background})`,
          }}
        ></div>
        <div className="max-w-screen-xl mx-auto text-left my-8 px-8">
          <h1 className="text-3xl font-bold mb-8">About Us</h1>
          <p className="mb-8">
            No Longer Naked Lures, LLC is a family-owned fishing tackle design,
            manufacturing and distribution company based out of Dallas, TX. We
            focus our lure design efforts on the use of natural attractants. Our
            goal is to not only bring the fish to you but also make your fishing
            experience pleasing to the eye, and as natural as possible.
          </p>
          <p className="mb-8">
            We aspire to provide anglers with the resources needed to integrate
            natural phenomena into the fishing lures they will be visualizing
            throughout the time they spend in an aquatic environment.
          </p>
          <p className="mb-8">
            Celebrate nature, it’s free and easy but remember to Leave It Better
            Than You Found It! (LIBTYFI)
          </p>
          <h1 className="text-3xl font-bold mt-16 mb-8">Our Mission</h1>
          <p className="mb-8">
            To promote the use of fishing lures designed to display natural
            attractants whilst giving nature the credit it deserves
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
