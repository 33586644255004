import React from "react";
import logo from "../assets/logo-white.png";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
function OrderSuccessful() {
  return (
    <div className=" h-screen flex items-center justify-center text-left  text-white">
      <div className="p-4 bg-[#6FA8DC] rounded ">
        <div className="flex justify-center">
          <img src={logo} className="max-w-sm" alt="nlnl logo" />
        </div>
        <div className="text-xl md:text-6xl font-bold">
          Congragulations on your order!
        </div>
        <div className="mt-8">
          Please check your email in the next few days for shipping
          confirmation.
        </div>
        <div className="mt-8">Thank you so much for your order!</div>
        <Link to="/">
          <div className="mt-8 text-lg md:text-3xl">
            <BiArrowBack />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default OrderSuccessful;
